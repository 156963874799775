import {
  isRouteErrorResponse,
  useParams,
  useRouteError,
} from 'react-router-dom';
import {
  ActivityView,
  ArticleView,
  BrowseView,
  CoursesView,
  CourseView,
  CreatePathwayView,
  CredentialsView,
  CredentialView,
  IndexView,
  PathwaysView,
  PathwayView,
  ProgramsView,
  ProgramView,
  PublicProfileView,
  RecommendedView,
  SkillsView,
} from './views';
import Error403 from './errors/pages/error403';
import Error404 from './errors/pages/error404';
import DashboardView from './views/DashboardView/DashboardView';
import ProfileView from './views/ProfileView';
import {
  Logout,
  SsoLogin,
  getUserDataFromCookie,
  CompleteLogin,
  sentryCreateBrowserRouter,
  getUserName,
  api,
} from '@iblai/ibl-web-react-common';
import CourseIframeCourseContentView from './views/content/CourseIframeCourseContentView/CourseIframeCourseContentView';
import CourseIframeCourseTabView from './views/content/CourseIframeCourseTabView/CourseIframeCourseTabView';
import { inIframe } from './utils/helpers';
import StaticClassView from './views/StaticClassView';
import { isStaticPagesEnabled } from './hooks/helpers';
import IntroductoryView from './views/IntroductoryView/IntroductoryView';
import { CourseUnitIframe } from './views/content/CourseUnitIframe/CourseUnitIframe';

const STATIC_PAGES_ENABLED = isStaticPagesEnabled();

const authCheckFn = (shouldBeAdmin, shouldBeLoggedIn, appData, setAppData) => {
  return async () => {
    if (!shouldBeLoggedIn) {
      return [];
    }
    const userDataFromCookie = getUserDataFromCookie();
    if (
      shouldBeLoggedIn &&
      (!appData?.axd_token ||
        !appData?.dm_token ||
        !userDataFromCookie ||
        userDataFromCookie?.user_id !== appData.userData?.user_id)
    ) {
      if (!inIframe()) {
        throw new Response(JSON.stringify({ shouldBeLoggedIn }), {
          status: 401,
        });
      } else {
        return [];
      }
    }
    return new Promise((resolve, reject) => {
      const getUserMetadataPromise = new Promise((_resolve, _reject) => {
        if (!Object.keys(appData.userMetaData).length) {
          api.ibledxusers.getUsersManageMetadata(
            { username: getUserName() },
            function (data) {
              _resolve({ userMetaData: { ...data }, userMetaDataLoaded: true });
            },
            () => {
              _reject();
            }
          );
        } else {
          resolve({});
        }
      });
      const getTenantsPromise = new Promise((_resolve, _reject) => {
        if (!localStorage.getItem('tenants')) {
          api.ibledxtenants.getUserTenants((tenants) => {
            const selectedTenant = localStorage.getItem('tenant');
            api.iblutils.saveUserTenantsDataToLocalStorage(
              tenants,
              selectedTenant
            );
            _resolve({
              current_tenant: localStorage.getItem('current_tenant'),
              tenants: localStorage.getItem('tenants'),
            });
          });
        } else {
          _resolve({});
        }
      });
      Promise.all([getUserMetadataPromise, getTenantsPromise])
        .then(([_userMetaDataInfo, _tenantInfo]) => {
          setAppData({ ...appData, ..._userMetaDataInfo, ..._tenantInfo });
          if (shouldBeAdmin && !_tenantInfo.current_tenant.is_admin) {
            throw new Response('Not Admin', { status: 403 });
          }
          resolve([]);
        })
        .catch((error) => {
          reject('Failed to fetch User tenants / Metadata');
        });
    });
  };
};

const ErrorPage = () => {
  const error = useRouteError();
  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <Error404 />;
    } else if (error.status === 403) {
      return <Error403 />;
    } else if (error.status === 401) {
      if (JSON.parse(error.data)?.shouldBeLoggedIn) {
        localStorage.setItem('redirect-path', window.location.pathname);
        window.location.href = `${process.env.REACT_APP_IBL_SPA_AUTH_URL}/login?redirect-to=${window.location.origin}`;
      }
    }
  }
};

const CourseIframeCourseTabWrapper = () => {
  const params = useParams();
  let tab = params.tab;
  if (params.hasOwnProperty('*')) {
    tab += `/${params['*']}`;
  }

  return <CourseIframeCourseTabView tab={tab} key={tab} />;
};

const route = (appData, setAppData) => {
  const routes = [
    {
      path: '/',
      errorElement: <ErrorPage />,
      children: [
        ...(STATIC_PAGES_ENABLED
          ? [
              {
                path: '',
                element: <StaticClassView />,
                loader: authCheckFn(false, false, appData, setAppData),
              },
              {
                path: 'public',
                element: <StaticClassView />,
                loader: authCheckFn(false, false, appData, setAppData),
                children: [
                  {
                    path: ':publicPath',
                    element: <StaticClassView />,
                  },
                ],
              },
            ]
          : []),
        {
          path: '',
          element: <DashboardView />,
          children: [
            {
              path: `${STATIC_PAGES_ENABLED ? `${process.env.REACT_APP_IBL_STATIC_PAGES_ENABLED_DASHBOARD_PATH}` : ''}`,
              element: <IndexView />,
              loader: authCheckFn(false, true, appData, setAppData),
            },
            {
              path: 'profile',
              element: <ProfileView />,
              loader: authCheckFn(false, true, appData, setAppData),

              children: [
                {
                  path: 'activity',
                  element: <ActivityView />,
                },
                {
                  path: 'credentials',
                  element: <CredentialsView />,
                },
                {
                  path: ':credentials/:credentialID',
                  element: <CredentialView />,
                },
                {
                  path: 'pathways/:pathwayID/:username',
                  element: <PathwayView />,
                },
                {
                  path: 'pathways/new',
                  element: <CreatePathwayView />,
                },
                {
                  path: 'skills',
                  element: <SkillsView />,
                },
                {
                  path: 'pathways',
                  element: <PathwaysView />,
                },
                {
                  path: 'courses',
                  element: <CoursesView />,
                },
                {
                  path: 'programs',
                  element: <ProgramsView />,
                },
                {
                  path: 'public-profile',
                  element: <PublicProfileView />,
                },
              ],
            },
            {
              path: 'courses/:courseID',
              element: <CourseView />,
              loader: authCheckFn(false, true, appData, setAppData),
            },
            {
              path: 'programs/:programID',
              element: <ProgramView />,
              loader: authCheckFn(false, true, appData, setAppData),
            },
            {
              path: 'courses/',
              element: <ArticleView />,
              loader: authCheckFn(false, true, appData, setAppData),

              children: [
                {
                  path: ':courseID',
                  element: <CourseIframeCourseContentView />,
                  children: [
                    {
                      path: 'course',
                      element: <CourseUnitIframe />,
                    },
                    {
                      path: ':tab/*', // Matches any subpath not matched by above
                      element: <CourseIframeCourseTabWrapper />,
                    },
                  ],
                },
              ],
            },
            {
              path: 'recommended',
              element: <RecommendedView />,
              loader: authCheckFn(false, true, appData, setAppData),
            },
            {
              path: 'discover',
              element: <BrowseView />,
              loader: authCheckFn(false, false, appData, setAppData),
            },
          ],
        },
        ...(process.env.REACT_APP_IBL_APP_ENABLE_START_ROLE === 'true'
          ? [
              {
                path: 'start',
                element: <IntroductoryView />,
              },
            ]
          : []),
        {
          path: 'certificates/:credentialID',
          element: <CredentialView />,
        },
        {
          path: 'login/complete',
          element: <CompleteLogin />,
        },
        {
          path: 'sso-login',
          element: <SsoLogin />,
          loader: authCheckFn(false, false, appData, setAppData),
        },
        {
          path: 'logout',
          element: (
            <Logout
              redirectTo={
                STATIC_PAGES_ENABLED
                  ? window.location.origin
                  : `${process.env.REACT_APP_IBL_SPA_AUTH_URL}/login?redirect-to=${window.location.origin}`
              }
            />
          ),
          loader: authCheckFn(false, false, appData, setAppData),
        },
      ],
    },
  ];

  return sentryCreateBrowserRouter(routes);
};

export default route;
