import React, { useContext, useEffect, useState } from 'react';
import { getFirstAvailableUnit, getIframeURL } from '../hooks/utils/iframe';
import { getParameterByName } from '../hooks/helpers';
import { useParams } from 'react-router-dom';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
import { GLOBAL_COLORS } from '@iblai/ibl-web-react-common';
import { IoCheckmarkCircle } from 'react-icons/io5';
import { MdOutlineRadioButtonUnchecked } from 'react-icons/md';
import './CourseIframeSideItem.css'

const CourseSideItemContext = React.createContext(null);
const CourseSideItemProvider =  CourseSideItemContext.Provider

const CourseIframeSideItem = ({
                                courseData,
                                setEdxIframe,
                                changeIframeLoadingStatus,
                                unitIdFromUrl,
                                setUnitIdFromUrl,
                              }) => {
  const { courseID } = useParams();
  const [activeCourseID, setActiveCourseID] = useState(unitIdFromUrl)
  const [activeSecondLevelOutline, setActiveSecondLevelOutline] = useState(null)
  const [activeFirstLevelOutline, setActiveFirstLevelOutline] = useState(null)
  const [initialTree, setInitialTree] = useState([])
  const [expandedSections, setExpandedSections] = useState({});

  const handleAccordionClick = (index) => {
    setExpandedSections((prevExpandedSections) => ({
      ...prevExpandedSections,
      [index]: !prevExpandedSections[index],
    }));
  };

  const handleIframeUrl = (url) => {
    setEdxIframe(url);
  };

  const setHighlightOnMenu = () => {
    const unitId =
      getParameterByName('unit_id') || unitIdFromUrl ||  getFirstAvailableUnit(courseData).id;
    setUnitIdFromUrl(unitId);
    setActiveCourseID(unitId)
  };

  const handleOutlineClick = (parentIndex, event) => {
    const dataId = event.currentTarget.getAttribute('data-id');
    const currentUnitId = getParameterByName('unit_id');

    const firstUnitId = getFirstAvailableUnit(courseData).id;

    // If there's no unit_id in the URL and this is  the first element, return early
    if (!currentUnitId && dataId === firstUnitId) {
      return;
    }
    if (dataId !== getParameterByName('unit_id')) {
      const newURL = `/courses/${courseID}/course?unit_id=${dataId}`;
      changeIframeLoadingStatus(true);
      window.history.pushState(null, 'Course', newURL);
      setHighlightOnMenu();
      getIframeURL(courseID, courseData, handleIframeUrl);
    }
  };

  useEffect(() => {
    getIframeURL(courseID, courseData, handleIframeUrl);
    setHighlightOnMenu();
  }, [courseData]);

  return (
    <CourseSideItemProvider value={{
      handleOutlineClick,
      setHighlightOnMenu,
      activeCourseID,
      activeSecondLevelOutline,
      activeFirstLevelOutline,
      setActiveCourseID,
      setActiveSecondLevelOutline,
      setActiveFirstLevelOutline
    }} >
      {courseData?.children?.map((item, index) => (
        <div className="faq-question-wrap-3" key={index}>
          <CourseOutline defaultOpen={index===0} key={`course-outline-${index}`} item={item} index={index} />
        </div>
      ))}
    </CourseSideItemProvider>
  );
};

const CourseOutline = ({ item, index, defaultOpen=false }) => {
  const [open, setIsOpen] = useState(defaultOpen);

  return (
    <div className="faq-question-wrap-3" key={index}>
      <a
        data-collapsed={'true'}
        href="#"
        className="first-level-outline faq-question-bar-3 w-inline-block"
        onClick={() => setIsOpen(!open)}
      >
        <div className="div-block-632 w-clearfix">
          {
            open ? <FaChevronRight size={15} color={GLOBAL_COLORS.primary} /> :
              <FaChevronDown size={15} color={GLOBAL_COLORS.primary} />
          }
          <div className="question-title-4 f">{item.display_name}</div>
        </div>
      </a>
      {
        open && Array.isArray(item.children) &&
        item.children.map((childItem, childIndex) => {
           return Array.isArray(childItem.children) &&
             childItem.children.length > 0 &&
             <SecondLevelOutline defaultOpen={childIndex===0} index={`${index}-${childIndex}`} key={`${index}-${childIndex}`} innerChildItem={childItem} />
            /*childItem.children.length > 1 ?
              <SecondLevelOutline defaultOpen={childIndex===0} index={`${index}-${childIndex}`} key={`${index}-${childIndex}`} innerChildItem={childItem} />
              :
              <ThirdLevelOutline  defaultOpen={childIndex===0} index={`${index}-${childIndex}`} key={`${index}-${childIndex}`} data={childItem} />*/
          }
        )
      }
    </div>
  );

};

const SecondLevelOutline = ({ innerChildItem, index, defaultOpen=false }) => {
  const [open, setIsOpen] = useState(defaultOpen);

  return (
    <>
      <a
        data-collapsed={'true'}
        href="#"
        className="second-level-outline faq-question-bar-3 w-inline-block"
        onClick={() => setIsOpen(!open)}
      >
        <div className="div-block-632 w-clearfix">
          {
            open ? <FaChevronRight size={15} color={GLOBAL_COLORS.primary} /> :
              <FaChevronDown size={15} color={GLOBAL_COLORS.primary} />
          }
          <div className="question-title-4 f">{innerChildItem.display_name}</div>
        </div>
      </a>
      {
        open && Array.isArray(innerChildItem.children) &&
        innerChildItem.children.map((innerGrandChildItem, grandChildIndex) => (
          <ThirdLevelOutline  defaultOpen={grandChildIndex===0} index={grandChildIndex} key={`${index}-${grandChildIndex}`} data={innerGrandChildItem} />
        ))
      }
    </>
  );
};

const ThirdLevelOutline = ({ data, index, defaultOpen=false }) => {
  const [open, setIsOpen] = useState(defaultOpen);
  const {handleOutlineClick, activeCourseID} = useContext(CourseSideItemContext)

  return (
    <div
      className={`third-level-outline faq-content-3 open`}
    >

      <div
        className={`inner open`}
      >
        <div
          data-id={data.id}
          //className={`module_row ${isActive ? 'active' : ''}`}
          className={`module_row ${activeCourseID === data.id ? "active" : ""}`}
          onClick={(event) =>
            handleOutlineClick(index, event)
          }
        >
          <div className="div-block-632 w-clearfix">
            <div className="inner_text">
              {
                data?.scored ?
                  <IoCheckmarkCircle color={GLOBAL_COLORS.primary} size={17} style={{
                    minWidth:"17px",
                  }}  />
                  :
                  <MdOutlineRadioButtonUnchecked  color={GLOBAL_COLORS.primary} size={17}
                                                  style={{
                                                    minWidth:"17px",
                                                  }}
                  />
              }

              <a
                href="#"
                //className={`c_l ${isActive ? 'active-link' : ''}`}
                className={`c_l`}
              >
                {data.display_name}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseIframeSideItem;
